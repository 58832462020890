
import Curso from "@/interfaces/Curso";
import Turma from "@/interfaces/Turma";
import router from "@/router";
import CursoService from "@/services/admin/CursoService";
import TurmaService from "@/services/admin/TurmaService";
import DivisaoService from "@/services/auth/DivisaoService";
import store from "@/store";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const route = useRoute();
    const turmas = ref<Turma[]>();
    const cursos = ref<Curso[]>([]);
    const loading = ref<boolean>(false);
    const tipo = ref<string>("");

    const goTo = (rota: string): void => {
      router.push({ name: rota });
    };

    const mudarTipo = (novoTipo: string): void => {
      tipo.value = novoTipo;
    };

    const getTurmas = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getTurmas(store.getters.divisaoId)
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        TurmaService.list()
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getCursos = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getCursos(store.getters.divisaoId)
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        CursoService.list()
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    onMounted(() => {
      getTurmas();
      getCursos();
    });

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return {
      turmas,
      cursos,
      store,
      loading,
      tipo,
      goTo,
      getTurmas,
      getCursos,
      mudarTipo,
    };
  },
});
